<template>
    <p class="text-large font-heading mb-3">{{ text }}</p>
</template>

<script>
import { mapActions } from 'vuex';
import { QueryParam } from '@/enums';

export default {
    name: 'Login',
    layout: 'loading',
    props: {
        text: {
            type: String,
            default() {
                return this.$t('loginPage.processingText');
            },
        },
        isDailyChat: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        const loginParams = {
            sessionId: this.$queryParams(QueryParam.SessionId),
            signInCode: this.$queryParams(QueryParam.SignInCode),
            clientId: this.$queryParams(QueryParam.ClientId),
            sourceChannel: this.$queryParams(QueryParam.sourceChannel),
            referral: this.$queryParams(QueryParam.Referral) || null,
            isDailyChat: this.isDailyChat,
        };

        this.onLoginLink(loginParams);
    },
    methods: {
        ...mapActions('auth', ['onLoginLink']),
    },
};
</script>
